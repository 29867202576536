var refreshIntervalId;
var feedArea;
var initAutoFeed = function() {
	feedArea = document.getElementById('div_feeds');
	if(feedArea){
		fetchFeed();
		// Fetch Feed every 10 seconds automatically.
		refreshIntervalId = setInterval(fetchFeed, 10000);
	} else {
		clearInterval(refreshIntervalId);
	}
};

var fetchFeed = function() {
	let url = 'https://sports.yahoo.com/golf/rss.xml';
	feednami.setPublicApiKey('99626f6beffb331cfa5d0e4d7ab3dd2a2e096a2da9aea91006f3c48319fa1924')
	feednami.load(url)
    .then(feed => {
    	if(feedArea) {
    		feedArea.innerHTML = " ";
	      for(let entry of feed.entries){
	        feedArea.innerHTML += "<li><a href='" + entry.link + "' target='_blank'>" + entry.title + "</a></li>";
	      }
    	}
    });
};

$(document).on('turbolinks:load', initAutoFeed);
