var is_calendar_mode = true;
var is_openning_modal = false;
var initialize_calendar = function() {
  $('.calendar').each(function(){
    var calendar = $(this);
    calendar.fullCalendar({
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      defaultView: "month",
      // defaultDate: moment(gon.position),
      // defaultDate: moment(String(position)),
      selectable: true,
      selectHelper: true,
      editable: true,
      // displayEventTime: false,
      eventLimit: true,
      eventOrder: ['order'],
      eventOrderStrict: true,
      events: window.location.pathname + '.json',

      select: function(start, end, jsEvent, view) {
        var currentDate = $('.calendar').fullCalendar('getDate').format();
        var starts_at = moment(start).format();
        var ends_at = moment(end).format();
        starts_at = moment(currentDate).format('YYYY-MM-DD') == moment(start).format('YYYY-MM-DD') ? currentDate : starts_at;
        var url = window.location.pathname + '/new?starts_at=' + starts_at + '&ends_at=' + ends_at;
        if (is_openning_modal != true) {
          is_openning_modal = true;
          $.getScript(url, function() {
            setTimeout(function() { is_openning_modal = false;}, 1000);
          });
          calendar.fullCalendar('unselect');
        }
      },

      eventDrop: function(event, delta, revertFunc) {
        if(event.id && !event.is_recurring) {
          // if (!window.confirm("Are you sure about this change?")) {
          //   revertFunc();
          // } else {
            var url = window.location.pathname + '/' + event.id + '?method=patch';
            event_data = { 
              event: {
                id: event.id,
                starts_at: event.start.format(),
                ends_at: event.end.format()
              }
            };
            $.ajax({
                url: url,
                data: event_data,
                type: 'PATCH'
            });
        } else {
          revertFunc();
        }
       },
      
      eventClick: function(event, jsEvent, view) {
        if(event.id) {
          var url = window.location.pathname + '/' + event.id + '/edit';
          $.getScript(url, function() {});
        }
      },

      eventRender: function(eventObj, $el) {
        if (eventObj.allDay == true) {
          $($el).find('.fc-event-time').remove();
        }
        var title = eventObj.game_format_name ? eventObj.title + " -- Game Format: " + eventObj.game_format_name : eventObj.title;
        var content = eventObj.game_format_scoring ? 'Rules: ' + eventObj.game_format_rules + ' -- Scoring: ' + eventObj.game_format_scoring : eventObj.description;
        $el.popover({
          title: title,
          content: content,
          trigger: 'hover',
          placement: 'top',
          container: 'body'
        });
      },
    });
  });
};

window.reload_event_table = function() {
  var dates = get_calendar_date_range();  
  isLoading(true);
  $.ajax({
    type: 'GET',
    url: "/events",
    data: { start: dates.start.toISOString().split('T')[0], end: dates.end.toISOString().split('T')[0], league_id: $('#hdn_league_id').val()},
    dataType: 'script',
    success: function(response){
      isLoading(false);
    }
  });
};
var get_calendar_date_range = function() {
  var calendar = $('.calendar-container .calendar').fullCalendar('getCalendar');
  var view = calendar.view;
  var start = view.start._d;
  var end = view.end._d;
  var option = view.intervalUnit;
  if (option === 'month') {
    var str_arr = view.title.split(' ');
    start = new Date(str_arr[0] + ' 1, ' + str_arr[1]);
    start.setHours(0,0,0,0);
    end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    end.setHours(0,0,0,0);
  }
  return { start: start, end: end };
};

$(document).on('turbolinks:load', function(){
  initialize_calendar();
  $('.list-container').hide();
  setTimeout(function() {
    $('.calendar-container button.fc-prev-button').click(function (ev) {
      reload_event_table();
    });
    $('.calendar-container button.fc-next-button').click(function (ev) {
      reload_event_table();
    });
    $('.calendar-container button.fc-today-button').click(function (ev) {
      reload_event_table();
    });
    $('.calendar-container button.fc-month-button').click(function (ev) {
      reload_event_table();
    });
    $('.calendar-container button.fc-agendaWeek-button').click(function (ev) {
      reload_event_table();
    });
    $('.calendar-container button.fc-agendaDay-button').click(function (ev) {
      reload_event_table();
    });
  }, 1000);
  $('#btn_toggle_view').click(function (ev) {
    is_calendar_mode = !is_calendar_mode;
    if (is_calendar_mode === true) {
      $('.list-container').hide();
      $('.calendar-container .fc-view-container').show();
      $('#btn_toggle_view').text('Show List View');
      $('.calendar').fullCalendar('refetchEvents');
    } else {
      $('.calendar-container .fc-view-container').hide();
      $('.list-container').show();
      $('#btn_toggle_view').text('Show Calender View');
      reload_event_table();
    }
  });
  $('#new_event_button').click(function(ev) {
    var dates = get_calendar_date_range();
    var url = $(this).attr('data-path');
    url = url + '?starts_at=' + dates.start.toISOString().split('T')[0] + '&ends_at=' + dates.end.toISOString().split('T')[0];
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      success: function(response){
      }
    });
  });
  $('#print_events_button').click(function(ev) {
    var dates = get_calendar_date_range();
    var url = $(this).attr('data-path');
    url = url + '?start=' + dates.start.toISOString().split('T')[0] + '&end=' + dates.end.toISOString().split('T')[0];
    window.open(url, '_blank');
  });
});
