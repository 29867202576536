var activate = function(data_id) {
  $.ajax({
    type: 'POST',
    url: "/memberships/activate",
    data: { id: data_id},
    dataType: 'json',
    success: function(response){
      if(response){
      }else{
        alert('error');
      }
    }
  });
};

var deactivate = function(data_id) {
  $.ajax({
    type: 'POST',
    url: "/memberships/deactivate",
    data: { id: data_id},
    dataType: 'json',
    success: function(response){
      if(response){
      }else{
        alert('error');
      }
    } 
  });
};

$(document).on('turbolinks:load', function() {
  $(".current-member").click(function(){
    var check = $(this).is(":checked");
    if (check == true){
      activate($(this).attr('data-id'));
    } else{
      deactivate($(this).attr('data-id'));
    }
  });

  $("#status_filter" ).change(function() {
    var value = $(this).val();
    var urlArr = window.location.href.split('?');
    location.href=urlArr[0] + "?status="+value;
  });
});
