// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import jquery from "jquery";
import "jstz";
window.$ = window.jQuery = window.jquery = jquery;
//= require jquery_ujs
//= require recurring_select

import "bootstrap3";
import moment from "moment";
window.moment = moment;
import daterangepicker from 'daterangepicker'
window.daterangepicker = daterangepicker;

import 'fullcalendar';

require('../src/feednami-client-v1.1');
require('../src/welcome');
require('../src/event');
require('../src/full_calendar');
require('../src/date_range_picker');
require('../src/memberships');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).ready(function() {
    $('.has-tooltip').tooltip();
});

window.eventCalendar = function eventCalendar() {
    return $('#calendar').fullCalendar({ });
}
window.clearCalendar = function clearCalendar() {
    $('#calendar').fullCalendar('delete'); 
    $('#calendar').html('');
}

window.isLoading = function isLoading(flag) {
    if(flag == true) $('.loading-container').show();
    else $('.loading-container').hide();
}

$(document).on('turbolinks:load', function(){
    eventCalendar();  
});
$(document).on('turbolinks:before-cache', clearCalendar);

$('#calendar').fullCalendar({ 
    events: '/events.json'
});

$('#calendar').fullCalendar({
    eventSources: ["/events"]
});