var o_starts_at, o_ends_at, is_recurring, is_allday;

var change_competition_event_status = function(event_type) {
  if(['Tournament Competition', 'Regular Competition'].includes(event_type)) {
      $("#event_form .competition_event").show();
    } else {
      $("#event_form .competition_event").hide();
    }
};

var change_recurring_options = function() {
  $("#event_form .event_date_range").hide();
  $("#event_form .event_date_time_range").hide();
  $("#event_form .event_recurring_range").hide();
  $("#event_form .event_recurring_time_range").hide();
  $("#event_form .event_manipulate_event").hide();
  $("#event_form .event_recurring_rules").hide();
  if(is_recurring) {
    $("#event_form .event_recurring_rules").show();
    if(!is_allday) {
      $("#event_form .event_recurring_time_range").show();
    } else {
      $("#event_form .event_recurring_range").show();
    }
  } else {
    if(!is_allday) {
      $("#event_form .event_date_time_range").show();
    } else {
      $("#event_form .event_date_range").show();
    }
  }
  if(is_allday) {
    $("#event_starts_at").val(moment(o_starts_at, ['YYYY-MM-DD']).startOf('day').format('MMMM D, YYYY hh:mm:ss A'));
    $("#event_ends_at").val(moment(o_starts_at, ['YYYY-MM-DD']).endOf('day').format('MMMM D, YYYY hh:mm:ss A'));
  } else {
    $("#event_starts_at").val(moment(o_starts_at, ['YYYY-MM-DD']).format('MMMM D, YYYY hh:mm A'));
    $("#event_ends_at").val(moment(o_ends_at, ['YYYY-MM-DD']).format('MMMM D, YYYY hh:mm A'));
  }
};

var change_allday_options = function() {
  $("#event_form .event_date_range").hide();
  $("#event_form .event_date_time_range").hide();
  $("#event_form .event_recurring_range").hide();
  $("#event_form .event_recurring_time_range").hide();
  $("#event_form .event_manipulate_event").hide();
  $("#event_form .event_recurring_rules").hide();
  if(is_recurring) {
    $("#event_form .event_recurring_rules").show();
    if(!is_allday) {
      $("#event_form .event_recurring_time_range").show();
    } else {
      $("#event_form .event_recurring_range").show();
    }
  } else {
    if(!is_allday) {
      $("#event_form .event_date_time_range").show();
    } else {
      $("#event_form .event_date_range").show();
    }
  }
  if(is_allday) {
    $("#event_starts_at").val(moment(o_starts_at, ['YYYY-MM-DD']).startOf('day').format('MMMM D, YYYY hh:mm:ss A'));
    $("#event_ends_at").val(moment(o_ends_at, ['YYYY-MM-DD']).endOf('day').format('MMMM D, YYYY hh:mm:ss A'));
  } else {
    $("#event_starts_at").val(moment(o_starts_at, ['YYYY-MM-DD']).format('MMMM D, YYYY hh:mm A'));
    $("#event_ends_at").val(moment(o_ends_at, ['YYYY-MM-DD']).format('MMMM D, YYYY hh:mm A'));
  }
};

var check_event_type = function() {
  change_competition_event_status($("#event_form #event_event_type").val());
  $("#event_form #event_event_type").on('change', function(){
    change_competition_event_status($(this).val());
  });
};

var check_recurring = function() {
  change_recurring_options();
  $("#event_form #event_is_recurring").on('change', function() {
    is_recurring = this.checked;
    change_recurring_options();
  });
  $('#event_recurring_rules option').filter(function() { 
    return ($(this).text().includes('*'));
  }).prop('selected', true);
};

var check_allday = function() {
  change_allday_options();
  $("#event_form #event_allday").on('change', function(){
    is_allday = this.checked
    change_allday_options();
  });
};

var init_recurring_time_range_picker = function() {
  var starts_at = $("#event_recurring_start_date").val() ? moment.utc($("#event_recurring_start_date", ['YYYY-MM-DD']).val(), ) : moment();
  var ends_at = $("#event_recurring_end_date").val() ? moment.utc($("#event_recurring_end_date", ['YYYY-MM-DD']).val()) : moment(starts_at, ['YYYY-MM-DD']).add(1, 'y');
  var drops = $(window).height() > 650 ? 'down' : 'up';

  if(!$("#event_recurring_start_date").val()) {
    $("#event_recurring_start_date").val(starts_at.format('MMMM D, YYYY'));
    $("#event_recurring_end_date").val(ends_at.format('MMMM D, YYYY'));
  }
  
  $('#event_recurring_time_range').daterangepicker({
    timePicker: true,
    drops: drops,
    startDate: starts_at,
    endDate: ends_at,
    ranges: {
      '7 Days': [moment(), moment().add(7, 'd')],
      '30 Days': [moment(), moment().add(30, 'd')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      '3 Month': [moment(), moment().add(3, 'M')],
      '6 Month': [moment(), moment().add(6, 'M')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
    }, 
    locale: {
      format: 'MMMM D, YYYY hh:mm A'
    }
  }).on('apply.daterangepicker', function(ev, picker) {
    $("#event_recurring_start_date").val(moment(picker.startDate, ['YYYY-MM-DD']).format('MMMM D, YYYY'));
    $("#event_recurring_end_date").val(moment(picker.endDate, ['YYYY-MM-DD']).format('MMMM D, YYYY'));
  }).on('show.daterangepicker', function(ev, picker) {
    if(picker.chosenLabel === 'Custom Range') {
      $(".drp-calendar.right").show();
      $(".drp-buttons > .drp-selected").show();
    } else {
      $(".drp-calendar.right").hide();
      $(".drp-buttons > .drp-selected").hide();
    }
  }).on('showCalendar.daterangepicker', function() {
    $(".drp-calendar.right").show();
    $(".drp-buttons > .drp-selected").show();
  });
  var drp = $('#event_recurring_time_range').data('daterangepicker');
  $('#event_recurring_time_range').click(function(e) {
    if($(window).height() > 650) {
      $('.drp-modal').animate({ scrollTop: $('.drp-modal').height() }, 50, function() {
        drp.move();
      });
    } else {
      $('.drp-modal').animate({ scrollTop: 0 }, 50, function() {
        drp.move();
      });
    }
  });
};

var init_recurring_range_picker = function() {
  var starts_at = $("#event_recurring_start_date").val() ? moment.utc($("#event_recurring_start_date").val()) : moment();
  var ends_at = $("#event_recurring_end_date").val() ? moment.utc($("#event_recurring_end_date").val()) : moment(starts_at, ['YYYY-MM-DD']).add(1, 'y');
  var drops = $(window).height() > 650 ? 'down' : 'up';
  if(!$("#event_recurring_start_date").val()) {
    $("#event_recurring_start_date").val(starts_at.format('MMMM D, YYYY'));
    $("#event_recurring_end_date").val(ends_at.format('MMMM D, YYYY'));
  }
  $('#event_recurring_range').daterangepicker({
    drops: drops,
    startDate: starts_at,
    linkedCalendars: false,
    endDate: ends_at,
    ranges: {
      '7 Days': [moment(), moment().add(7, 'd')],
      '30 Days': [moment(), moment().add(30, 'd')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      '3 Month': [moment(), moment().add(3, 'M')],
      '6 Month': [moment(), moment().add(6, 'M')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
    }, 
    locale: {
      format: 'MMMM D, YYYY'
    }
  }).on('apply.daterangepicker', function(ev, picker) {
    $("#event_recurring_start_date").val(moment(picker.startDate, ['YYYY-MM-DD']).format('MMMM D, YYYY'));
    $("#event_recurring_end_date").val(moment(picker.endDate, ['YYYY-MM-DD']).format('MMMM D, YYYY'));
  }).on('show.daterangepicker', function(ev, picker) {
    $(".drp-calendar.right").hide();
    $(".drp-buttons > .drp-selected").hide();
  });

  var drp = $('#event_recurring_range').data('daterangepicker');
  
  $('#event_recurring_range').click(function(e) {
    if($(window).height() > 650) {
      $('.drp-modal').animate({ scrollTop: $('.drp-modal').height() }, 50, function() {
        drp.move();
      });
    } else {
      $('.drp-modal').animate({ scrollTop: 0 }, 50, function() {
        drp.move();
      });
    }
  });
};

var init_date_time_range_picker = function() {
  $.fn.modal.Constructor.prototype.enforceFocus = $.noop; // FIX:time not changeable in Firefox

  var starts_at = o_starts_at ? moment.utc(o_starts_at, ['YYYY-MM-DD']) : moment();
  var ends_at = o_ends_at ? moment.utc(o_ends_at, ['YYYY-MM-DD']) : moment(starts_at, ['YYYY-MM-DD']).add(1, 'h');
  var drops = $(window).height() > 650 ? 'down' : 'up';
  $('#event_date_time_range').daterangepicker({
    timePicker: true,
    startDate: starts_at,
    endDate: ends_at,
    drops: drops,
    locale: {
      format: 'MMMM D, YYYY hh:mm A'
    }
  }).on('apply.daterangepicker', function(ev, picker) {
    $("#event_starts_at").val(moment(picker.startDate, ['YYYY-MM-DD']).format('MMMM D, YYYY hh:mm A'));
    $("#event_ends_at").val(moment(picker.endDate, ['YYYY-MM-DD']).format('MMMM D, YYYY hh:mm A'));
  }).on('show.daterangepicker', function(ev, picker) {
    $(".drp-calendar.right").show();
    $(".drp-buttons > .drp-selected").show();
  });
  var drp = $('#event_date_time_range').data('daterangepicker');
  $('#event_date_time_range').click(function(e) {
    if($(window).height() > 650) {
      $('.drp-modal').animate({ scrollTop: $('.drp-modal').height() }, 50, function() {
        drp.move();
      });
    } else {
      $('.drp-modal').animate({ scrollTop: 0 }, 50, function() {
        drp.move();
      });
    }
  });
};

var init_date_range_picker = function() {
  $.fn.modal.Constructor.prototype.enforceFocus = $.noop; // FIX:time not changeable in Firefox
  var starts_at = o_starts_at ? moment.utc(o_starts_at, ['YYYY-MM-DD']) : moment();
  var ends_at = o_ends_at ? moment.utc(o_ends_at, ['YYYY-MM-DD']) : moment(starts_at, ['YYYY-MM-DD']).add(1, 'h');
  var drops = $(window).height() > 650 ? 'down' : 'up';
  $('#event_date_range').daterangepicker({
    linkedCalendars: false,
    startDate: starts_at,
    endDate: ends_at,
    drops: drops,
    locale: {
      format: 'MMMM D, YYYY'
    }
  }).on('apply.daterangepicker', function(ev, picker) {
    $("#event_starts_at").val(moment(picker.startDate, ['YYYY-MM-DD']).format('MMMM D, YYYY'));
    $("#event_ends_at").val(moment(picker.endDate, ['YYYY-MM-DD']).format('MMMM D, YYYY'));
  }).on('show.daterangepicker', function(ev, picker) {
    $(".drp-calendar.right").hide();
    $(".drp-buttons > .drp-selected").hide();
  });
  var drp = $('#event_date_range').data('daterangepicker');
  $('#event_date_range').click(function(e) {
    if($(window).height() > 650) {
      $('.drp-modal').animate({ scrollTop: $('.drp-modal').height() }, 50, function() {
        drp.move();
      });
    } else {
      $('.drp-modal').animate({ scrollTop: 0 }, 50, function() {
        drp.move();
      });
    }
  });
};

$(document).on('show.bs.modal','.modal', function () {
  if($("#event_form").length) {
    o_starts_at = $("#event_starts_at").val();
    o_ends_at = $("#event_ends_at").val();
    is_allday = $("#event_form #event_allday").is(":checked");
    is_recurring = $("#event_form #event_is_recurring").is(":checked");

    init_date_range_picker();
    init_date_time_range_picker();
    init_recurring_range_picker();
    init_recurring_time_range_picker();
    check_event_type();
    check_recurring();
    check_allday();
  }
});

$(document).on('turbolinks:load', function() {
  if($("#event_form").length) {
    o_starts_at = $("#event_starts_at").val();
    o_ends_at = $("#event_ends_at").val();
    is_allday = $("#event_form #event_allday").is(":checked");
    is_recurring = $("#event_form #event_is_recurring").is(":checked");

    init_date_range_picker();
    init_date_time_range_picker();
    init_recurring_range_picker();
    init_recurring_time_range_picker();
    check_event_type();
    check_recurring();
    check_allday();
  }
});
